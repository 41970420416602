<template>
  <div class="design">
    <div class="banner">
      <img @click="goContact" src="@/assets/images/menu/banner1.jpg" alt=""  />
    </div>
    <div class="design-main">
      <div class="content">
        <div class="desc">
          <div class="title">惠享工业企业服务平台平台入驻资料清单</div>
          <div class="text">
            惠享工业企业服务平台平台一期上线采用邀请模式入驻，收到
            邀请的服务商需准备如下资料：<br />
            截止日期：5 月 1 号 <br />
            <b>一、入驻协议 </b><br />
            1. 打印入驻协议。 <br />
            2. 负责人签字，加盖公章骑缝章。 <br />
            3. 拍照或扫描备用。 <br />
            <b>二、营业执照</b><br />
            1. 彩色复印营业执照副本。 <br />
            2. 在彩色复印件上手写：
            “用于注册惠享工业企业服务平台平台帐号和开通商业版店 铺使用。
            提交日期：2021 年 4 月 X 日。” <br />
            3. 压字盖章，拍照或扫描备用。 <br />
            <b>三、生产许可证 </b><br />
            1. 彩色复印生产许可证副本。 <br />
            2. 拍照或扫描备用。 <br />
            <b>四、发送资料 </b><br />
            1. 电子版 <br />
            ①加盖公章的入驻协议照片或扫描件。<br />
            ②营业执照彩色照片或扫描件。（手写“用于注册惠享
            工业互联网平台帐号和开通商业版店铺使用。 提交日期：2021 年 4 月 X
            日。”） <br />
            ③生产许可证照片或扫描件。 发送至电子邮箱：huixianggongye@163.com <br />
            2. 纸质版 <br />
            ①负责人签署并加盖公章的入驻协议。 <br />
            ②手写盖章的营业执照彩色复印件，打印原件。 <br />
            ③生产许可证彩色复印件。 发送至：吉林省长春市难关区蔚山路绿地中央广场
            B10A 1865
            <div class="r" id="zongjingli">
              惠享招商部负责人：姜经理 <br />
              联系电话：15043091216
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
   
    this.$parent.finish ? this.$parent.finish() : null;
    
  },
    methods: {
   goContact() {
      $("body,html").animate(
        { scrollTop: $("#zongjingli").offset().top - 150 },
        300
      );
    },}
};
</script>
<style lang="scss">
.design {
  .banner {
    img {
      width: 100%;
      display: block;
    }
  }
  .design-main {
    margin: 50px auto;
    .desc {
      border: solid 1px #f2f2f2;
    }
    .title {
      background-color: #f2f2f2;
      line-height: 50px;
      font-size: 22px;
      text-align: center;
    }
    .text {
      padding: 30px;
      font-size: 16px;
      line-height: 2;
      .r {
        text-align: right;
      }
    }
  }
}
</style>